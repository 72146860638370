import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col } from "react-bootstrap";
import SignIn from "./pages/SignIn";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { Outlet } from "react-router-dom";
import NavBar from "./components/Menu";

function App() {
	const { user } = useContext(AuthContext);
	return (
		<Container>
			<Row>
				<Col>
					{user.hasOwnProperty("jwtToken") ? (
						<>
							<NavBar />
							<Outlet />
						</>
					) : (
						<SignIn />
					)}
				</Col>
			</Row>
		</Container>
	);
}

export default App;
