import { useState, useContext } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "../utils/axiosClient";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { useEffect } from "react";

function SignIn(params) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showWarning, setShowWarning] = useState(false);
	const navigate = useNavigate();
	const location = useLocation()
	const { user, signIn } = useContext(AuthContext);

	const handleEmailChange = (e) => {
		checkForWarning();
		let value = e.target.value;
		setEmail(value);
	};
	const handlePasswordChange = (e) => {
		checkForWarning();
		let value = e.target.value;
		setPassword(value);
	};
	const checkForWarning = () => {
		if (showWarning) setShowWarning(false);
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		let response = await axios.post("/admin/login", { email, password });
		checkForWarning();
		if (response.status === 200) {
			signIn(response.data);
			navigate(location.pathname,{replace:true});
		} else {
			setShowWarning(true);
		}
	};
	useEffect(() => {});
	return (
		<div className="w-50 p-3 m-auto mt-5">
			<Form>
				<Form.Group className="mb-3 mt-3" controlId="formBasicEmail">
					<Form.Label>Email address</Form.Label>
					<Form.Control
						type="email"
						placeholder="Enter email"
						value={email}
						onChange={handleEmailChange}
					/>
				</Form.Group>

				<Form.Group className="mb-3 mt-3" controlId="formBasicPassword">
					<Form.Label>Password</Form.Label>
					<Form.Control
						type="password"
						placeholder="Password"
						value={password}
						onChange={handlePasswordChange}
					/>
				</Form.Group>
				<Button variant="primary" type="submit" onClick={handleSubmit}>
					Submit
				</Button>
			</Form>
			{showWarning && (
				<div class="alert alert-danger mt-4" role="alert">
					Invalid credentials
				</div>
			)}
		</div>
	);
}

export default SignIn;
