import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link, Navigate, Outlet } from "react-router-dom";
import axiosClient from "../utils/axiosClient";
import { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import UserPendingTasksTable from "../components/UserPendingTasksTable";
function AdminDashBoard(props) {
	const { user } = useContext(AuthContext);
	return user.hasOwnProperty("jwtToken") ? (
		<Outlet />
	) : (
		<Navigate to="/"></Navigate>
	);
}

export default AdminDashBoard;
