import React, { useRef, useState } from "react";
import axiosClient from "../utils/axiosClient";
import { useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import Button from "react-bootstrap/Button";

export default function AddPartner() {
	const { user } = useContext(AuthContext);
	const [partners, setPartners] = useState([]);
	const [showForm, setShowForm] = useState(false);
	const [partnerName, setPartnerName] = useState("");
	const [partnerMaxCredits, setPartnerMaxCredits] = useState(0);
	const [fileName, setFileName] = useState("");
	const [partnersToUpdate, setPartnersToUpdate] = useState({});
	const [saveEdit, setSaveEdit] = useState(false);
	const [partnerUrl, setPartnerUrl] = useState("");
	const fileRef = useRef(null);

	const fetchPartners = async () => {
		try {
			let response = await axiosClient.get("/admin/partners", {
				headers: {
					Authorization: user.jwtToken,
				},
			});
			setPartners(response.data.partners);
		} catch (error) {}
	};
	useEffect(() => {
		console.log(partners)
	},[partners])
	const hideForm = async (e) => {
		if (fileName && partnerName) {
			let logoFile = fileRef.current.files[0];
			const formData = new FormData();
			formData.append("logo", logoFile);
			formData.append("name", partnerName);
			formData.append("maxCredits", partnerMaxCredits);
			formData.append("url", partnerUrl);
			let response = await axiosClient.post("/admin/createPartner", formData, {
				headers: {
					"content-type": "multipart/form-data",
					Authorization: user.jwtToken,
				},
			});
			setPartnerName("");
			setPartnerMaxCredits(0);
			setFileName("");
			setShowForm(false);
			if (response.status === 200) {
				await fetchPartners();
			}
		}
	};

	const updatePartners = async () => {
		console.log("updating");
		let partnersToUpdateArray = Object.entries(partnersToUpdate).map(
			([k, v]) => ({ id: k, maxCredits: v })
		);
		let response = await axiosClient.post(
			"/admin/updatePartners",
			{ partnersToUpdate: partnersToUpdateArray },
			{
				headers: {
					Authorization: user.jwtToken,
				},
			}
		);
		await fetchPartners();
		setSaveEdit(false);
	};
	const updateFileName = (e) => {
		setFileName(e.target.files[0].name);
	};
	const displayForm = (e) => {
		setShowForm(true);
	};
	useEffect(() => {
		fetchPartners();
	}, []);

	const updatePartnersMax = (partner_id, e) => {
		let value = e.target.value;

		let updatedPratners = partners.map((e) => {
			if (e.id === partner_id) {
				e.maxCredits = value;
				let partnerObj = {
					...partnersToUpdate,
					[partner_id]: value,
				};
				setPartnersToUpdate(partnerObj);
			}
			return e;
		});
		setSaveEdit(true);
		setPartners(updatedPratners);
	};

	return (
		<div className="partners-container">
			<div className="p-3 dashboard_heading">
				<h1>PARTNERS</h1>
				{saveEdit ? (
					<Button variant="success" onClick={updatePartners}>
						SAVE EDITS
					</Button>
				) : null}
			</div>
			<div className="partner mb-2" key={0}>
				<h5 className="partner-id">ID</h5>
				<h5 className="ms-2 partner-name">PARTNER_NAME</h5>
				<h5 className="ms-2 partner-name">MAX_CREDITS</h5>
				<h5 className="ms-2 partner-name">PARTNER_URL</h5>
				<h5 className="mb-0">LOGO</h5>
			</div>
			{partners.map((partner) => (
				<div className="partner mb-2" key={partner.id}>
					<p className="partner-id">{partner.id}</p>
					<p className="ms-2 partner-name">{partner.name}</p>
					<p className="ms-2 partner-name">
						<input
							type="number"
							value={partner.maxCredits}
							onChange={(e) => updatePartnersMax(partner.id, e)}
						></input>
					</p>
					<p className="ms-2 partner-name">
						url
					</p>
					<img
						src={`http://18.169.192.137:5001/${partner.logo}`}
						alt={partner.name}
						height="40px"
					></img>
				</div>
			))}

			<form
				className={
					showForm ? "partner mb-2" : "partner mb-2 partner-form-hidden"
				}
			>
				<input className="partner-id" disabled placeholder="Automatic" />
				<input
					className="ms-2 partner-name"
					type="text"
					value={partnerName}
					onChange={(e) => setPartnerName(e.target.value)}
				/>
				<input
					className="ms-2 partner-name"
					type="number"
					value={partnerMaxCredits}
					onChange={(e) => setPartnerMaxCredits(e.target.value)}
				/>
				<input
					className="ms-2 partner-name"
					type="text"
					value={partnerUrl}
					onChange={(e) => setPartnerUrl(e.target.value)}
				/>
				<label htmlFor="partner-input" className="ms-4 p-2 bg-warning rounded">
					<p>LOGO &#8593;</p>
					<p>{fileName}</p>
					<input
						ref={fileRef}
						id="partner-input"
						onChange={updateFileName}
						className="partner-input"
						type="file"
						placeholder="logo"
					/>
				</label>
			</form>

			<div className="d-grid gap-2">
				{showForm ? (
					<Button variant="success" size="lg" onClick={hideForm}>
						<span className="display-6">Submit</span>
					</Button>
				) : (
					<Button variant="success" size="lg" onClick={displayForm}>
						<span className="display-4 partner-plus">+</span>
					</Button>
				)}
			</div>
		</div>
	);
}
