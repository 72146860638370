import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import AdminDashBoard from "./pages/AdminDashBoard";
import AuthProvider from "./context/AuthContext";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignIn from "./pages/SignIn";
import UserPendingTasksTable from "./components/UserPendingTasksTable";
import AddPartner from "./components/AddPartner";
import Users from "./components/Users"

ReactDOM.render(
	<React.StrictMode>
		<AuthProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<App />}>
						<Route path="login" element={<SignIn />}></Route>
						<Route path="admindashboard" element={<AdminDashBoard />}>
							<Route path="partners" element={<AddPartner />}></Route>
							<Route
								path="pending-tasks"
								element={<UserPendingTasksTable />}
							></Route>
							<Route
								path="users"
								element={<Users />}
							></Route>
						</Route>
					</Route>
				</Routes>
			</BrowserRouter>
		</AuthProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
