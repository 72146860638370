import axios from "axios";

// const baseURL = process.env.NODE_ENV === "production" ? 
// process.env.REACT_APP_ENV === "staging" ? 
// "https://api-qa.foxdeal.ch" : 
// "https://api.foxdeal.ch" : 
// "http://localhost:5000";

const baseURL = process.env.NODE_ENV === "production" ? 
process.env.REACT_APP_ENV === "staging" ? 
"https://api-qa.foxdeal.ch" : 
"https://apifox.isolvedev.website" : 
"http://localhost:5000";

export default axios.create({
  baseURL,
});