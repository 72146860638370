import React, { useState } from "react";

export default function SearchBar(props) {
	const [search, setSearch] = useState("");
	const [searchTerm, setSearchTerm] = useState("Email");
	const updateSearch = (e) => {
		let value = e.target.value;
		setSearch(value);
		props.setSearch(value);
	};
	const updateSearchTerm = (e) => {
		let value = e.target.value;
		setSearchTerm(value);
		props.setSearchTerm(value.toLowerCase());
	};
	return (
		<div className="search pb-3">
			<label>
				Search by{" "}
				<select
					value={searchTerm}
					onChange={updateSearchTerm}
					className="p-2 bg-primary text-white rounded"
				>
					<option value="Email">email</option>
					<option value="Name">name</option>
				</select>
			</label>
			<input
				className="ms-3 search-input"
				type="text"
				value={search}
				onChange={updateSearch}
			></input>
		</div>
	);
}
