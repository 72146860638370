import { Navbar, Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
function NavBar() {
	return (
		<Navbar bg="primary" variant="dark" className="p-3 mt-3 mb-3 rounded-1">
			<Container>
				<Navbar.Brand>
					<Link to="/">
						<img src="/new_fox.svg" className="logo" alt="main logo" />
					</Link>
				</Navbar.Brand>
				<Nav className="me-auto">
					<Link
						to="/admindashboard/pending-tasks"
						className="nav-link Menu_nav-link"
					>
						PENDING
					</Link>
					<Link
						to="/admindashboard/partners"
						className="nav-link Menu_nav-link"
					>
						PARTNERS
					</Link>
					<Link
						to="/admindashboard/users"
						className="nav-link Menu_nav-link"
					>
						USERS
					</Link>
				</Nav>
			</Container>
		</Navbar>
	);
}

export default NavBar;
