import { useContext, useRef } from "react";
import { AuthContext } from "../context/AuthContext";
import { Link, Navigate } from "react-router-dom";
import axiosClient from "../utils/axiosClient";
import { useEffect } from "react";
import { useState } from "react";
import { Table, Button } from "react-bootstrap";
import SearchBar from "./SearchBar";
function UserPendingTasksTable(props) {
	const { user } = useContext(AuthContext);
	const [pendingTasks, setPendingTasks] = useState([]);
	const [completedTasks, setCompletedTasks] = useState([]);
	const [saveEdit, setSaveEdit] = useState(false);
	const [focusedRecord, setFocusedRecord] = useState(null);
	const [search, setSearch] = useState("");
	const [serachTerm, setSearchTerm] = useState("email");
	const [pendingTasksCopy, setPendingTasksCopy] = useState([]);
	const [psuedoProvison,setPsuedoProvision] = useState(0)
	const provisonRef = useRef([])

	const fetchPendingTasks = async () => {
		try {
			const response = await axiosClient.get("/admin/getPendingTasks", {
				headers: {
					Authorization: user.jwtToken,
				},
			});
			setPendingTasks(response.data);
			setPendingTasksCopy(response.data);
		} catch (error) {
			console.log(error);
		}
	};
	const updatePendingTasks = async () => {
		try {
			const response = await axiosClient.post(
				"/admin/updatePendingTasks",
				{ completedTasks },
				{
					headers: {
						Authorization: user.jwtToken,
					},
				}
			);
			setSaveEdit(false);
			setCompletedTasks([]);
			await fetchPendingTasks();
		} catch (error) {
			console.log(error);
		}
	};
	const updateMarkAsCompleted = (task) => {
		let taskAlreadyInCompletedTask = completedTasks.find(
			(e) => e.id === task.id
		);
		if (taskAlreadyInCompletedTask) {
			setCompletedTasks(completedTasks.filter((e) => e.id !== task.id));
		} else {
			setCompletedTasks((tasks) => [...tasks, task]);
		}
	};
	const updatePendingTaskCredits = (task_id, ev) => {
		let tasks = pendingTasks.map((e) => {
			if (e.id === task_id) {
				let value = ev.target.value;
				e.credits = parseFloat(value);
			}
			return e;
		});

		// task.credits = parseFloat(e.target.value)
		setPendingTasks(tasks);
	};
	const focusOnRecord = (id) => {
		setFocusedRecord(id);
	};
	const updateProvison = (task_id,i,e) =>{
		let value = e.target.value
		setPsuedoProvision(value)
		provisonRef.current[i].value = value
	}
	useEffect(() => {
		fetchPendingTasks();
	}, []);
	useEffect(() => {
		let filteredPendingTasks = pendingTasksCopy.filter((e) =>
			e.User[serachTerm].includes(search)
		);
		setPendingTasks(filteredPendingTasks);
		if (!search.trim()) {
			setPendingTasks(pendingTasksCopy);
		}
	}, [search]);

	useEffect(() => {
		completedTasks.length > 0 ? setSaveEdit(true) : setSaveEdit(false);
	}, [completedTasks]);
	return user.hasOwnProperty("jwtToken") ? (
		<div>
			<div className="p-3 dashboard_heading">
				<h1>USER PENDING TASKS</h1>
				{saveEdit ? (
					<Button variant="success" onClick={updatePendingTasks}>
						SAVE EDITS
					</Button>
				) : null}
			</div>
			<SearchBar
				serachTerm={serachTerm}
				setSearchTerm={setSearchTerm}
				setSearch={setSearch}
			/>
			<Table striped bordered hover>
				<thead>
					<tr>
						{pendingTasks.length > 0 ? (
							<>
								<th key="id">ID</th>
								<th key="user_id">USER_ID</th>
								<th key="partner_id">PARTNER_NAME</th>
								<th key="credits">CREDITS</th>
								<th key="provision">PROVISION</th>
								<th key="percentage">PERCENTAGE</th>
								<th key="email">EMAIL</th>
								<th key="user_name">USER_NAME</th>
								<th key="phone">MOBILE</th>
								<th key="state">STATE</th>
							</>
						) : null}
					</tr>
				</thead>
				<tbody>
					{pendingTasks.length > 0
						? pendingTasks.map((task,i) => (
								<tr
									key={task.id}
									onClick={() => focusOnRecord(task.id)}
									className={
										task.id === focusedRecord ? `focused_record` : null
									}
								>
									<td>{task.id}</td>
									<td>{task.userId}</td>
									<td>{task.Task.Partner.name}</td>
									<td className="text-center">
										<input
											type="number"
											value={task.credits}
											className="rounded w-75 text-center"
											onChange={(e) => updatePendingTaskCredits(task.id, e)}
										></input>
									</td>
									<td className="text-center">
										<input
											ref={el => provisonRef.current[i] = el}
											type="number"
											defaultValue={task.credits}
											className="rounded w-75 text-center"
											onChange={(e) => updateProvison(task.id,i, e)}
										></input>
									</td>
									<td>{((task.credits / provisonRef?.current[i]?.value || task.credits) * 100).toFixed(1)} %</td>
									<td>{task.User.email}</td>
									<td>{task.User.name}</td>
									<td>{task.User.phone}</td>
									<td>
										<label>
											COMPLETE
											<input
												key={task.id}
												className="ms-3"
												type="checkbox"
												onChange={() => updateMarkAsCompleted(task)}
											></input>
										</label>
									</td>
								</tr>
						  ))
						: null}
				</tbody>
			</Table>
		</div>
	) : (
		<Navigate to="/"></Navigate>
	);
}

export default UserPendingTasksTable;
