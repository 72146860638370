import React, { Component } from "react";
import axiosClient from "../utils/axiosClient";
export const AuthContext = React.createContext();

class AuthProvider extends Component {
	state = {
		user: {},
	};

	signIn = async (userInfo) => {
		this.setState({user:userInfo})
	};

	render() {
		return (
			<AuthContext.Provider
				value={{
					user: this.state.user,
					signIn:this.signIn
				}}
			>
				{this.props.children}
			</AuthContext.Provider>
		);
	}
}

export default AuthProvider;
