import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import axiosClient from "../utils/axiosClient";
import { Table, Button } from "react-bootstrap";

export default function Users() {
	const { user } = useContext(AuthContext);
	const [users, setUsers] = useState([]);
	const [saveEdit, setSaveEdit] = useState(false);
	const [updatedUsers, setUpdatedUsers] = useState([]);
	const fetchUsers = async () => {
		try {
			let response = await axiosClient.get("/admin/getUsers", {
				headers: {
					Authorization: user.jwtToken,
				},
			});
			setUsers(response.data.users);
		} catch (error) {
			console.log(error);
		}
	};
	const updateUserCredits = (userId, e) => {
		let value = e.target.value;
		let updatedUsersArray = users.map((user) => {
			if (user.id === userId) {
				user.credits = value;
				if (updatedUsers.find((e) => e.id === user.id)) {
					let userIndex = updatedUsers.findIndex((e) => e.id === user.id);
					let newUpdatedUsers = updatedUsers.map(u => {
						if(u.id === updatedUsers[userIndex].id)
							updatedUsers[userIndex].credits = value;
						return u
					})

					setUpdatedUsers(newUpdatedUsers)
				} else {
					let userObj = { id: user.id, credits: value };
					setUpdatedUsers(updatedUsers.concat(userObj));
				}
			}
			return user;
		});
		setSaveEdit(true);
		setUsers(updatedUsersArray);
	};

	const updateUsers = async() => {
		console.log("updating");
		let response = await axiosClient.post('/admin/updateUsers',{usersToUpdate:updatedUsers},{headers:{
			Authorization:user.jwtToken
		}})
		console.log(response)
		setSaveEdit(false);
	};

	// useEffect(() => {
	// 	console.log(users);
	// }, [users]);

	useEffect(() => {
		console.log(updatedUsers);
	}, [updatedUsers]);

	useEffect(() => {
		fetchUsers();
	}, []);

	return (
		<div>
			<div className="p-3 dashboard_heading">
				<h1>USERS</h1>
				{saveEdit ? (
					<Button variant="success" onClick={updateUsers}>
						SAVE EDITS
					</Button>
				) : null}
			</div>
			<div>
				<Table striped bordered hover>
					<thead>
						<tr>
							<th key="id">ID</th>
							<th key="email">EMAIL</th>
							<th key="name">NAME</th>
							<th key="phone">PHONE</th>
							<th key="credits">CREDITS</th>
						</tr>
					</thead>
					<tbody>
						{users.map((user) => (
							<tr key={user.id}>
								<td>{user.id}</td>
								<td>{user.email}</td>
								<td>{user.name}</td>
								<td>{user.phone}</td>
								<td>
									<input
										type="number"
										value={user.credits}
										className="rounded w-75 text-center"
										onChange={(e) => updateUserCredits(user.id, e)}
									></input>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			</div>
		</div>
	);
}
